import StimulusFlatpickr from "stimulus-flatpickr";
import flatpickr from "flatpickr";

import { German } from "flatpickr/dist/l10n/de.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Italian } from "flatpickr/dist/l10n/it";
import { english } from "flatpickr/dist/l10n/default.js";
import { t } from "../i18n/t";

import "flatpickr/dist/themes/light.css";

import { throttle } from "underscore";
import { useDispatch } from "stimulus-use";
import moment from "moment";

//
// Instead of using the rangePlugin which is in beta, it was decided to workaround by
// attaching event handlers to provided hooks.
//
export default class extends StimulusFlatpickr {
  static targets = [
    // Used as inputs, values are in `altFormat` (yyyy-mm-dd)
    "firstInput",
    "secondInput",
    "flatpickrInput",
    "instance",
    "dateRangeErrorsHolder",
    "checkAvailabilityLink",
    "availabilityLink",
    // Holder element where the calendar will be appended to
    "holder",
  ];

  static values = {
    duration: String,
  };

  locales = {
    de: German,
    de_formal: German,
    fr: French,
    it: Italian,
    en: english,
  };

  availabilities = {};
  firstAvailableDate = null;

  initialize() {
    var that = this;
    let reloadAvailabilities_ = throttle(() => {
      this.reloadAvailabilities();
    }, 300);

    let availabilities_ = this.availabilities;
    // global options to be merged to others specified via data attributes
    this.config = {
      locale: {
        ...this.locale,
        firstDayOfWeek: 1,
      },
      animate: false,
      // allowInvalidPreload: true, // dates in the past, etc.
      mode: "range",
      disable: this.disableDays(availabilities_, reloadAvailabilities_),
      onMonthChange: [
        function (_selectedDates, _dateStr, instance) {
          if (that.hasAvailabilityLinkTarget) {
            var currentMonth = $(".cur-month")[1]
              ? $(".cur-month")[1].innerHTML
              : moment($(".flatpickr-monthDropdown-months").val(), "M").format("MMMM");
            var currentYear = $(".cur-year").val();
            var nextMonth = `${currentYear} ${currentMonth} 1`;
            var data = that.fetchAvailableDates(nextMonth, instance);
          }
        },
      ],
      onYearChange: [
        function (_selectedDates, _dateStr, instance) {
          if (that.hasAvailabilityLinkTarget) {
            var currentMonth = $(".cur-month")[1]
              ? $(".cur-month")[1].innerHTML
              : moment($(".flatpickr-monthDropdown-months").val(), "M").format("MMMM");
            var currentYear = $(".cur-year").val();
            var nextMonth = `${currentYear} ${currentMonth} 1`;
            var data = that.fetchAvailableDates(nextMonth, instance);
          }
        },
      ],

      // the rangePlugin is broken re: several details, cannot be enabled
      // plugins: [new rangePlugin({input: ".date_to"})]
    };
    if (this.hasHolderTarget) {
      this.config.appendTo = this.holderTarget;
    }

    // Set showMonth of flatpickr according to request
    if (!this.detectMobile()) {
      this.config.showMonths = 2;
    }
  }

  connect() {
    useDispatch(this);
    super.connect();

    const input = this.hasFlatpickrInputTarget ? this.flatpickrInputTarget : this.flatpickrElement;
    this.showPreview(this.fp, input, this.fp.selectedDates);
    if (this.hasAvailabilityLinkTarget) this.availableDates();
    if (this.firstAvailableDateURL) this.loadFirstAvailableDate();
  }

  resetAvailabilities() {
    let reloadAvailabilities_ = throttle(() => {
      this.reloadAvailabilities();
    }, 300);

    this.availabilities = {};
    let availabilities_ = this.availabilities;
    this.config.disable = this.disableDays(availabilities_, reloadAvailabilities_);

    this.delayedInitialization();
  }

  disableDays(availabilities, reloadAvailabilities) {
    return [
      function (date) {
        const localDate = new Date(date);
        localDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());

        const dayStr = localDate.toISOString().split("T")[0];
        if (availabilities[dayStr] === undefined) {
          reloadAvailabilities();
          // before data is loaded, make everything enabled;
          // otherwise intial dates are considered invalid and
          // not loaded.
          return false;
        } else {
          return !availabilities[dayStr];
        }
      },
    ];
  }

  // In order to use flatpickr inside modals we need a way to re-initialize
  // it after the modal become visible. If you initialize when hidden it cannot
  // calculate sizes properly.
  //
  delayedInitialization() {
    if (this.fp) {
      this.fp.destroy();
    }
    const element = this.hasFlatpickrInputTarget ? this.flatpickrInputTarget : this.flatpickrElement;
    this.fp = flatpickr(element, {
      ...this.config,
    });
  }

  onInputClicked(event) {
    this.delayedInitialization();
    this.onInputManuallyChanged();
    this.fp.redraw();
    this.fp.open();

    if (!!this.firstAvailableDate && !this.firstInputTarget.value && !this.secondInputTarget.value) {
      console.log("firstAvailableDate is loaded and inputs are clear...")
      // fallback for the case when no date is fetched
      this.firstAvailableDate = new Date();
      this.fp.jumpToDate(this.firstAvailableDate);
    }

    if (this.minuteManualDisabled) {
      $(".flatpickr-minute").prop("disabled", true);
    }
  }

  onInputManuallyChanged() {
    let first = this.firstInputTarget.value;
    let second = this.secondInputTarget.value;

    this.fp.setDate([first, second], true, this.dateFormat);
  }

  onKeyDown(event) {
    const first = this.firstInputTarget;
    const second = this.secondInputTarget;
    const enter = 13;
    const del = 46;
    const backspace = 8;

    switch (event.keyCode) {
      case enter: {
        const datesRangeSelected = first.value && second.value;
        if (datesRangeSelected) this.dispatchFilterEvent();

        break;
      }
      case del:
      case backspace: {
        first.value = null;
        second.value = null;
        this.dispatchFilterEvent();

        break;
      }
    }
  }

  onKeyPress() {
    // prevent input to be filled with visible symbols by keyboard
    if (event.charCode) event.preventDefault();
  }

  // onOpen gets triggered when the calendar is opened.
  // open() { }

  // onClose gets triggered when the calendar is closed.
  close(selectedDates, dateStr, instance) {
    if (selectedDates.length === 2) this.dispatchFilterEvent();
  }

  dispatchFilterEvent() {
    const filterEvent = new Event("filter", {
      bubbles: true,
      cancelable: true,
    });
    this.firstInputTarget.dispatchEvent(filterEvent);
  }

  // onValueUpdate gets triggered when the input value is updated
  valueUpdate(selectedDates, dateStr, instance) {
    let startDateStr = "";
    let startDate = null;

    let endDate = null;
    let endDateStr = "";

    let datesDefined = 0;

    if (selectedDates.length > 0) {
      startDate = selectedDates[0];
      startDateStr = instance.formatDate(startDate, this.altFormat);
      datesDefined++;
    }

    if (selectedDates.length > 1) {
      endDate = selectedDates[1];
      endDateStr = instance.formatDate(endDate, this.altFormat);
      datesDefined++;
    }

    this.firstInputTarget.value = startDateStr;
    this.secondInputTarget.value = endDateStr;

    if (datesDefined === 2) {
      this.checkAvailability(startDateStr, endDateStr);
    }
  }

  get galleryController() {
    return this.application.controllers.find((c) => c.identifier === "gallery");
  }

  // onChange gets triggered when the user selects a date, or changes the atime on a selected date.
  change(selectedDates, dateStr, instance) {
    this.dispatch("changed");
    // var data = that.fetchAvailableDates(nextMonth, instance)
    if (selectedDates.length == 1 && this.hasAvailabilityLinkTarget) {
      var date = selectedDates[0];
      var date2 = this.addDays(selectedDates[0], parseInt(this.durationValue));
      this.fp.currentMonth = date2.getMonth();
      this.fp.currentYear = date2.getFullYear();
      var data = this.fetchAvailableDates(date, instance);
      this.fp.redraw();
    } else if (selectedDates.length === 2 && this.galleryController) {
      this.showPreview(instance, instance.altInput, selectedDates);
      this.galleryController.load();
    }
  }

  showPreview(instance, input, selectedDates) {
    if (selectedDates.length < 2) {
      return;
    }

    const [first, second] = selectedDates;

    input.value = instance.formatDate(first, this.altFormat) + "-" + instance.formatDate(second, this.altFormat);
    input.classList.add("!text-green-brand");

    this.firstInputTarget.value = instance.formatDate(first, this.dateFormat);
    this.secondInputTarget.value = instance.formatDate(second, this.dateFormat);

    const dateHolder = $("#date-holder")[0];
    dateHolder.classList.remove("border-gray-300");
    dateHolder.classList.add("border-green-brand");
    dateHolder.classList.add("!border-2");
    dateHolder.setAttribute("data-toggle-open-value", false);
    dateHolder.setAttribute("data-dropdown-open-value", false);
  }

  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  checkAvailability(startDate, endDate) {
    if (this.hasCheckAvailabilityLinkTarget) {
      this.calculatePricing(startDate, endDate);
    }
    const url = this.data.get("checkAvailabilityUrl");
    const exceptBookingId = this.data.get("availabilityExceptBookingId");

    if (url === null || url === undefined) {
      return;
    }

    if (startDate && endDate) {
      const start = moment(startDate, this.dateFormat.toUpperCase()).startOf("day").add(12, "hours").unix();
      const end = moment(endDate, this.dateFormat.toUpperCase()).startOf("day").add(12, "hours").unix();

      if (this.hasDateRangeErrorsHolderTarget) {
        const errorsHolder = this.dateRangeErrorsHolderTarget;

        $.get(url, { start: start, end: end, except_booking_id: exceptBookingId }).done(function (data) {
          if (data.errors) {
            errorsHolder.innerHTML = `<span class="input__error_container"><div class="input__error">${data.errors[0]}</div></span>`;
            $(errorsHolder).show();
          } else {
            $(errorsHolder).hide();
          }
        });
      }
    }
  }

  // onMonthChange gets triggered when the month is changed, either by the user or programmatically.
  // monthChange() { }

  // onYearChange gets triggered when the year is changed, either by the user or programmatically.
  // yearChange() { }

  // onReady gets triggered once the calendar is in a ready state.
  ready(dateObj, dateStr, instance) {
    let footer = document.createElement("div");
    footer.classList.add("flatpickr-footer");
    instance.calendarContainer.append(footer);
    const locale = this.data.get("locale") || "en";
    instance.calendarContainer.classList.add("sticky-calendar");
    let clearBtn = document.createElement("div");
    clearBtn.classList.add("flatpickr-clear");
    clearBtn.onclick = () => {
      instance.clear();
      instance.close();
      this.firstInputTarget.value = null;
      this.secondInputTarget.value = null;
      this.dispatch("changed");
      let dateHolder = $("#date-holder")[0];
      if (dateHolder) {
        dateHolder.classList.add("border-gray-300");
        dateHolder.classList.remove("border-green-brand");
        dateHolder.classList.remove("!border-2");
      }

      if (this.galleryController) {
        this.galleryController.load();
      }

      this.dispatchFilterEvent();
    };
    clearBtn.innerHTML = t("scheduler.clear", locale);
    footer.append(clearBtn);

    if (this.firstInputTarget.value || this.secondInputTarget.value) {
      if (instance.altInput) instance.altInput.classList.add("filled");
    }
  }

  // Take full control of every date cell with theonDayCreate()hook.
  // dayCreate() { }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return this.locales["en"];
    }
  }

  get altFormat() {
    return this.data.get("altFormat");
  }

  get dateFormat() {
    return this.data.get("dateFormat");
  }

  get minuteManualDisabled() {
    return this.data.get("minuteManualDisabled") === "true";
  }

  get firstAvailableDateURL() {
    return this.data.get("firstAvailableDateUrl");
  }

  dateToUnixTimestampSeconds(d) {
    if (d === null || d === undefined) {
      return 0;
    } else {
      return d.getTime() / 1000;
    }
  }

  reloadAvailabilities() {
    const url = this.data.get("availabilityUrl");
    const exceptBookingId = this.data.get("availabilityExceptBookingId");

    if (url === null || url === undefined) {
      return;
    }

    if (this.fp === undefined) {
      return;
    }

    let availabilities = this.availabilities;
    const redraw = this.fp.redraw;

    // date range to query
    const millisecondsInMonth = 30 * 24 * 3600 * 1000;
    const firstDayCurrentMonth = new Date(this.fp.currentYear, this.fp.currentMonth, 1);
    const startTs = firstDayCurrentMonth.getTime() - millisecondsInMonth;
    const endTs = firstDayCurrentMonth.getTime() + millisecondsInMonth * 2;
    const start = startTs / 1000;
    const end = endTs / 1000;

    const startStr = new Date(startTs).toISOString().split("T")[0];
    const endStr = new Date(endTs).toISOString().split("T")[0];

    if (availabilities[startStr] !== undefined && availabilities[endStr] !== undefined) {
      return;
    }

    // parse json
    fetch(url + `?start=${start}&end=${end}&except_booking_id=${exceptBookingId}`)
      .then((response) => response.text())
      .then((data) => {
        // API returns one entry for each day between `start` and `end` (BookingCalendarSerializer..)
        JSON.parse(data).forEach(function (event, index) {
          availabilities[event.day] = event.status === true;
        });

        redraw();
      });
  }

  calculatePricing(start_date, end_date) {
    const that = this;
    if (start_date && end_date) {
      var start = moment(start_date, this.dateFormat.toUpperCase()).startOf("day").add(12, "hours").unix();
      var end = moment(end_date, this.dateFormat.toUpperCase()).startOf("day").add(12, "hours").unix();

      $.get(this.checkAvailabilityLinkTarget.dataset.url, { start: start, end: end }).done(function (data) {
        if (data.display_price) {
          console.log("booking details received");
        }
      });
    }
  }

  availableDates() {
    const that = this;

    $.get(this.availabilityLinkTarget.dataset.url).done(function (data) {
      data.forEach(function (date, index) {
        if (date.status == false || date.status == "booked") {
          that.config.disable.push(date.day);
        }
      });
      that.delayedInitialization();
    });
  }

  async loadFirstAvailableDate() {
    const that = this;
    if (!!that.firstAvailableDate) return;

    await fetch(this.firstAvailableDateURL)
      .then((response) => response.json())
      .then(({ date }) => {
        this.firstAvailableDate = new Date(date);
      })
      .catch((error) => console.error(error));
  }

  fetchAvailableDates(month) {
    if (!this.hasAvailabilityLinkTarget) return;

    let availabilities = this.availabilities;
    const redraw = this.fp.redraw;
    let start_date = moment(month).subtract(2, "months").unix();
    let end_date = moment(month).add(3, "months").unix();
    $.get(this.availabilityLinkTarget.dataset.url, { start: start_date, end: end_date }).done(function (data) {
      // API returns one entry for each day between `start` and `end` (BookingCalendarSerializer..)
      data.forEach(function (event, index) {
        availabilities[event.day] = event.status === true;
      });
      redraw();
    });
  }

  detectMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
}
